import { FC } from "react";
import { Button } from "@/components/ui/button"; // Assuming you have a button component in your ui directory
import { Link } from "react-router-dom";

const Footer: FC = () => {
  return (
    <footer className="bg-background text-foreground ">
      {/* The footer content container with only left and right borders */}

      <div className="container mx-auto px-4 border-x border-border py-6">
        <div className="flex items-center justify-between">
          {/* Footer Disclaimer or Additional Info */}
          <div className="text-sm text-muted-foreground">
            <p>&copy; 2024 BonusMaestro. All rights reserved.</p>
          </div>

          {/* Contact Info */}
          <div className="flex items-center">
            <p>
              Contact us:{" "}
              <a
                href="mailto:your-email@example.com"
                className="text-primary hover:text-primary-foreground"
              >
                support@bonusmaestro.com
              </a>
            </p>
          </div>

          {/* Social Media Links or Other Buttons */}
          {/* <div className="flex space-x-4">
            <Button className="bg-primary text-white">Follow Us</Button>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
