import AccordionComponent from "@/components/Accordion";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button"; // Assuming you have a button component
import React, { useEffect, useState } from "react";
import BonusCard from "@/components/BonusCard";

import bonusData from "@/data/bonuses.json"; // Adjust the path to your actual JSON file

// Define the Bonus type
interface Bonus {
  logo: string;
  name: string;
  bonusInfo: {
    bonusAmount: string;
    bonusPercentage: string;
    bonusWager: string;
  };
  claimUrl: string;
}

const LandingPage = () => {
  const [bonuses, setBonuses] = useState<Bonus[]>([]);

  useEffect(() => {
    // For now, we can just assign the data directly for simplicity
    setBonuses(bonusData);
  }, []);

  return (
    <>
      {/* Hero Section */}
      <section className="bg-secundary text-primary-foreground text-left py-12 px-4">
        <h1 className="text-4xl font-bold ">Welcome to BonusMaestro</h1>
        <p className="mt-4 text-lg">
          Your ultimate source for slot machines and casino bonuses!
        </p>

        {/* Button in Hero Section */}
        <div className="mt-6">
          <Button className="bg-primary  text-white py-2 px-6 rounded-lg">
            Get Started
          </Button>
        </div>
      </section>

      {/* ShadCN Divider/Separator */}
      <Separator className="px-0 mx-0" />

      <div className="container mx-auto py-12">
        <h1 className="text-3xl font-bold mb-8">Available Bonuses</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-2">
          {bonuses.map((bonus, index) => (
            <BonusCard
              key={index}
              logo={bonus.logo}
              name={bonus.name}
              bonusInfo={bonus.bonusInfo}
              claimUrl={bonus.claimUrl}
            />
          ))}
        </div>
      </div>

      <Separator />

      {/* Accordion Section for Slots and Bonuses */}
      <section className="py-5 bg-background text-foreground px-4">
        <h2 className="text-3xl font-semibold text-left mb-6">
          Learn More About Slots and Casino Bonuses
        </h2>
        <AccordionComponent />
      </section>

      {/* Optional: Add another separator if needed */}
      <Separator />
    </>
  );
};

export default LandingPage;
