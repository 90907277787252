import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "./ui/accordion";

const AccordionComponent = () => {
  return (
    <Accordion type="single" collapsible className="space-y-4">
      {/* Accordion Item 1: Slots */}
      <AccordionItem value="item-1">
        <AccordionTrigger className="text-foreground bg-card hover:bg-card/80 px-4 py-2 text-lg rounded-md w-full text-left">
          What are Slots?
        </AccordionTrigger>
        <AccordionContent className="p-4 bg-card text-card-foreground">
          <p>
            Slots are one of the most popular casino games where players spin
            the reels and try to match symbols to win. With a variety of themes,
            payouts, and bonus features, slots provide endless entertainment.
          </p>
        </AccordionContent>
      </AccordionItem>

      {/* Accordion Item 2: Casino Bonuses */}
      <AccordionItem value="item-2">
        <AccordionTrigger className="text-foreground bg-card hover:bg-card/80 px-4 py-2 text-lg rounded-md w-full text-left">
          Casino Bonuses
        </AccordionTrigger>
        <AccordionContent className="p-4 bg-card text-card-foreground">
          <p>
            Casino bonuses are promotions offered to players. These can include
            welcome bonuses, free spins, or deposit bonuses. Make sure to read
            the terms and conditions before claiming a bonus!
          </p>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionComponent;
