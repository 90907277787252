import React from "react";
import { Routes, Route } from "react-router-dom";

// Pages
import LandingPage from "@/pages/landingPage"; // Import the LandingPage component
import DefaultLayout from "@/layouts/defaultLayout"; // Import the DefaultLayout component

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Route for Home, using the LandingPage with DefaultLayout */}
        <Route
          path="/"
          element={
            <DefaultLayout>
              <LandingPage />
            </DefaultLayout>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
