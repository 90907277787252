import React from "react";
import { Button } from "@/components/ui/button"; // Assuming you have a button component

interface BonusCardProps {
  logo: string;
  name: string;
  bonusInfo: {
    bonusAmount: string;
    bonusPercentage: string;
    bonusWager: string;
  };
  claimUrl: string;
}

const BonusCard: React.FC<BonusCardProps> = ({
  logo,
  name,
  bonusInfo,
  claimUrl,
}) => {
  return (
    <div className="flex flex-col border border-border rounded-lg overflow-hidden bg-background shadow-md w-60 h-auto">
      {/* Top: Name */}
      <div className="p-4 text-center">
        <h3 className="text-lg font-semibold">{name}</h3>
      </div>

      {/* Middle: Logo */}
      <div className="p-4 flex justify-center ">
        <img src={logo} alt={name} className="h-16 w-16 object-contain" />
      </div>

      {/* Bonus Info */}
      <div className="p-4 space-y-2">
        <div className="flex justify-between">
          <span>Amount:</span>
          <span className="font-semibold">{bonusInfo.bonusAmount}</span>
        </div>
        <div className="flex justify-between">
          <span>Percentage:</span>
          <span className="font-semibold">{bonusInfo.bonusPercentage}</span>
        </div>
        <div className="flex justify-between">
          <span>Wager:</span>
          <span className="font-semibold">{bonusInfo.bonusWager}</span>
        </div>
      </div>

      {/* Bottom: Claim Button */}
      <div className="p-4">
        <a href={claimUrl} className="w-full">
          <Button className="bg-primary text-white w-full">Claim Bonus</Button>
        </a>
      </div>
    </div>
  );
};

export default BonusCard;
