import { FC } from "react";
import { Button } from "@/components/ui/button"; // Assuming you have a button component in your ui directory
import { Link } from "react-router-dom"; // If you are using react-router for navigation

const Header: FC = () => {
  return (
    <header className="bg-background text-foreground">
      {/* Outer container for the header */}
      <div className="container px-0">
        {/* Inner content of the header with a border */}
        <div className="border-x border-border py-6">
          <div className="flex justify-between items-center">
            {/* Logo on the left */}
            <div className="flex items-center space-x-2 px-4">
              <img
                src="/logoTransparant.png" // Correct path to logo image inside public/assets folder
                alt="SlotMaestro Logo"
                className="h-10 w-15" // Adjust the size of the logo
              />
              {/* Optional: Text beside logo */}
              {/* <span className="text-2xl font-bold">SlotMaestro</span> */}
            </div>

            {/* Navigation Links on the right */}
            <nav className="space-x-6">
              {/* <Link to="/" className="text-muted-foreground hover:text-primary">
                Home
              </Link>
              <Link to="/about" className="text-muted-foreground hover:text-primary">
                About
              </Link>
              <Link to="/contact" className="text-muted-foreground hover:text-primary">
                Contact
              </Link> */}
            </nav>

            {/* Optional: Call-to-action button */}
            <div>
              {/* <Button className="bg-primary hover:bg-primary-foreground text-white">
                Sign Up
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
